import { useState, useEffect } from "react";
import logo from "../../../assets/images/logo.png";
import header_icon2 from "../../../assets/images/header-icon2.svg";
import header_icon4 from "../../../assets/images/header-icon4.svg";
import header_icon6 from "../../../assets/images/header-icon6.svg";
import header_icon5 from "../../../assets/images/header-icon5.svg";
import header_icon7 from "../../../assets/images/header-icon7.svg";
import header_icon8 from "../../../assets/images/header-icon8.svg";
import logout from "../../../assets/images/logout.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../../actions";
import { OrderService } from "../../../services/OrderService";
import { ProductService } from "../../../services/ProductService";
import { alertActions } from "../../../actions/alert.actions";

const HeaderHooks = (propCount, propCart) => {
  // Daily Rate popup
  const [showDailyRate, setShowDailyRate] = useState(false);
  const openDailyRate = () => {
    document.title = `Gold rate today in India | Abharan Jewellers`;
    document.body.classList.add("modal-open");
    setShowDailyRate(true);
  };
  const closeDailyRate = () => {
    document.body.classList.remove("modal-open");
    setShowDailyRate(false);
  };

  if (showDailyRate) {
    var dailyDataDescription = {
      info: `Find the Gold rate in India today. Latest gold rates on Abharan`,
    };
  }

  // Search Bar popup
  const [showSearchBar, setShowSearchBar] = useState(false);

  //search menu
  const [showMenuBar, setShowMenuBar] = useState(true);

  useEffect(() => {
    setShowMenuBar(true);
  }, [showMenuBar]);

  const openSearch = () => {
    document.body.classList.add("modal-open");
    setShowSearchBar(true);
  };
  const closeSearch = () => {
    document.body.classList.remove("modal-open");
    setShowSearchBar(false);
  };

  //close menu
  const closeMenu = () => {
    //initial state is true
    if (showMenuBar === true) setShowMenuBar(!showMenuBar);
  };

  // Video call popup
  const [showVideoCall, setShowVideoCall] = useState(false);
  const openVideoCall = () => {
    document.body.classList.add("modal-open");
    setShowVideoCall(true);
  };
  const closeVideoCall = () => {
    document.body.classList.remove("modal-open");
    setShowVideoCall(false);
  };

  const user_id = useSelector((state) => state?.userData?.user?._id);
  const [count, setCount] = useState();
  const [cart, setCart] = useState(0);
  const [block, setBlock] = useState();

  // useEffect(() => {
  //   wishListCount();
  //   cartCountForDelete();
  //   cartCount();
  //   console.log("HeaderHook Count value inside useEffect", count);
  // }, [propCount, propCart, count, cart, user_id]);

  // useEffect(() => {
  //   ProductService.block_vdo().then((res) => {
  //     setBlock(res?.data?.data?.video?.[0]?.status);
  //   });
  // }, []);

  let history = useNavigate();
  const dispatch = useDispatch();
  const handleLogout = () => {
    dispatch(userLogout());
    history("/");
    //dispatch(alertActions.success("Logout Success"));
  };
  const handleLogin = () => {
    history("/login");
  };
  const handleLoginForNonUser = () => {
    history("/login");
    // setTimeout(() => {
    //   dispatch(alertActions.error("Please Login..."));
    // }, 50);
  };

  // const wishListCount = () => {
  //   if (user_id) {
  //     OrderService.getWishlist(user_id)
  //       .then((order) => {
  //         setCount(order?.data?.data?.total);
  //         return true;
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //         //   setLoading(false);
  //         return false;
  //       });
  //   }
  //   // return count;
  // };

  // const cartCount = () => {
  //   if (user_id) {
  //     ProductService.getCart().then((res) => {
  //       if (res?.data?.data?.cart_count) {
  //         setCart(res?.data?.data?.cart_count);
  //       } else {
  //         // setCart();
  //         // handleLogin();
  //       }
  //       // if(res.)
  //     });
  //     return cart;
  //   }
  // };
  // const cartCountForDelete = () => {
  //   if (user_id) {
  //     ProductService.getCart()
  //       .then((res) => {
  //         if (!res?.data?.data?.cart_count) {
  //           setCart(res?.data?.data?.cart_count);
  //         } else {
  //           // setCart();
  //         }
  //         return cart;
  //         //  window.location.reload();
  //       })
  //       .catch((error) => {
  //         console.log("Errorreg", error);
  //         //   setLoading(false);
  //       });
  //   }
  // };

  return {
    logo,
    header_icon2,
    header_icon4,
    header_icon6,
    header_icon5,
    header_icon7,
    header_icon8,
    logout,
    count,
    cart,

    handleLogout,
    handleLogin,
    closeMenu,
    showDailyRate,
    openDailyRate,
    closeDailyRate,
    showSearchBar,
    showMenuBar,
    openSearch,
    closeSearch,
    showVideoCall,
    openVideoCall,
    closeVideoCall,
    handleLoginForNonUser,
    block,
    dailyDataDescription,
  };
};
export default HeaderHooks;
