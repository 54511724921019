import feature1 from "../../../assets/images/feature1.webp";
import feature2 from "../../../assets/images/feature2.webp";
import feature3 from "../../../assets/images/feature3.webp";
import feature4 from "../../../assets/images/feature4.webp";
import { alertActions } from "../../../actions/alert.actions";
import Header from "../../_common/Header/Header";
import Footer from "../../_common/Footer/Footer";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Slider from "react-slick";
import "./HomePage.css";
import { Link, useNavigate } from "react-router-dom";
import { HomeService } from "../../../services/HomeService";
import diamond_below_two from "../../../assets/images/diamond-necklace.webp";
import gold_below_two from "../../../assets/images/gold-necklace.webp";
import akshayaTritiya from "../../../assets/images/akshayaTritiya.png";
import gem_necklace1 from "../../../assets/images/gem-necklace.webp";
import gem_necklace2 from "../../../assets/images/gem-necklace2.webp";
import gem_necklace3 from "../../../assets/images/gem-necklace3.webp";
import { useDispatch } from "react-redux";
import useCountHook from "../../../hooks/useCountHook";
import { UPDATE_ALL_FILTER, ADD_BANNER_DATA } from "../../../actions/types";
import CategoryContainer from "../../home/CategoryContainer";
import WhatsNew from "../../home/WhatsNew";
import { LazyLoadImage } from "react-lazy-load-image-component";
import vajrabharana from "../../../assets/images/vajrabharana.png";
import whatsapp_vaj from "../../../assets/images/whatsapp_vaj.svg";
import close_pop from "../../../assets/images/vaj_close.png";

const HomePage = () => {
  const [loading, setLoading] = useState("loading");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const image_url = process.env.REACT_APP_MEDIA_DOMAIN; // Image url
  const [marketing, setMarketing] = useState([]);
  const [bannerImages, setBannerImages] = useState();
  const { wishListCount, cartCount } = useCountHook();
  const [showModal, setShowModal] = useState(true);

  const onTopMainCategories = (value) => {
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        main_cat: [value],
        sub_cat: [],
        page_no: 1,
        min_amount: 0,
        max_amount: 0,
        search_key: "",
        filter: [],
      },
    });
    navigate(`/product-list/${value.toLowerCase().replace(/ /g, "-")}`);
  };
  // new code
  const onShopNowClicked = (value) => {
    let searchNew = value.toLowerCase().replace(/ /g, "-");
    let search;
    let maxAmount;
    let mainCat;

    if (searchNew === "gold-finger-ring") {
      search = "Gold Finger Ring";
      mainCat = "Gold";
      maxAmount = "30000";
    } else if (searchNew === "diamond-finger-ring") {
      search = "Diamond Finger Ring";
      mainCat = "Diamond";
      maxAmount = "50000";
    } else if (searchNew === "antique") {
      search = "Antique";
      mainCat = "Gold";
    } else if (searchNew === "gold-pendant") {
      search = "Gold Pendant";
      mainCat = "Gold";
    } else {
      search = value;
    }
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        page_no: 1,
        search_key: search ? search : " ",
        main_cat: [mainCat],
        sub_cat: [],
        min_amount: 0,
        max_amount: maxAmount ? maxAmount : 0,
        filter: [],
      },
    });
    navigate(`/product-list/${searchNew}`);
  };

  const imgChange2 = (value) => {
    let searchNew = value.toLowerCase().replace(/ /g, "-");
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        main_cat: ["Gold"],
        sub_cat: ["Necklace"],
        page_no: 1,
        min_amount: 1,
        max_amount: 200000,
        search_key: "",
        filter: [],
      },
    });
    navigate(`/product-list/${searchNew}s`);
  };

  const imgChange3 = (value) => {
    let searchNew = value.toLowerCase().replace(/ /g, "-");
    dispatch({
      type: UPDATE_ALL_FILTER,
      payload: {
        main_cat: ["Diamond"],
        sub_cat: ["Necklace"],
        page_no: 1,
        min_amount: 1,
        max_amount: 200000,
        search_key: "",
        filter: [],
      },
    });
    navigate(`/product-list/${searchNew}s`);
  };

  const Vajrabharana = () => {
    return (
      <div className="modalPop" id="decideModal">
        <div className="modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                onClick={closeVajrabharana}
              >
                <LazyLoadImage src={close_pop} alt="image" />
                {/* <i class="fa-solid fa-x"></i> */}
              </button>
              <div className="decide_box">
                <div className="decide_image">
                  <LazyLoadImage src={vajrabharana} />
                </div>
              </div>
              <div className="order_now">
                <a
                  href="https://api.whatsapp.com/send?phone=918861206178"
                  target="_blank"
                >
                  <button
                    className="orderNowBtn"
                    onClick={VajrabharanaWhatsapp}
                  >
                    <span className="buttonContent">
                      Chat Now <LazyLoadImage src={whatsapp_vaj} />
                    </span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  window.scrollTo(0, 0);
  useEffect(() => {
    const sessionVisited = sessionStorage.getItem("sessionVisited");
    if (sessionVisited) {
      // dispatch(alertActions.error("Other time"));
      setShowModal(false);
    } else {
      // dispatch(alertActions.success("First time"));
      localStorage.setItem("sessionVisited", "true");
    }
    HomeService.getBanner()
      .then((bannerImages) => {
        setBannerImages(bannerImages.data);
        dispatch({
          type: ADD_BANNER_DATA,
          payload: bannerImages.data.data,
        });
        setLoading("notempty");
        //  window.location.reload();
      })
      .catch((error) => {
        console.log("Errorreg", error);
        setLoading("empty");
        //   setLoading(false);
      });

    HomeService.remarket().then((response) => {
      setMarketing(response.data.data.details);
    });
  }, []);

  const BannerContainer = () => {
    var bannerSlider = {
      dots: false,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1500,
      // fade: true,
      // cssEase: "linear",
    };

    function mobileTab() {
      window.open(
        "https://www.abharan.com/product-list/gold-akshaya-tritiya-voucher"
      );
    }
    return (
      <div class="banner_container flt">
        <div></div>
        <Helmet>
          {marketing &&
            marketing.map((mark) => (
              <meta name="description" content={mark.metatag} />
            ))}
          <link rel="canonical" href={window.location.href} />
        </Helmet>

        <Slider
          autoplaySpeed={5000}
          speed={3500}
          infinite={true}
          arrows={false}
          dots={false}
          autoplay={true}
          slidesToShow={1}
          slidesToScroll={1}
          className="mobile_hide flt"
        >
          {bannerImages &&
          bannerImages.data &&
          bannerImages.data.banners !== null
            ? bannerImages.data.banners.map((banner_list, index) => (
                <div key={index}>
                  <a href={banner_list?.link}>
                    <LazyLoadImage
                      src={image_url + banner_list?.image}
                      alt="image"
                      key={banner_list?._id}
                      height="395px"
                      width="1351px"
                    />
                  </a>
                </div>
              ))
            : null}
        </Slider>

        <Slider {...bannerSlider} className="hide_desktop flt">
          {/* <LazyLoadImage
            src={akshayaTritiya}
            alt="mobile-banner"
            height="395px"
            width="100px"
            onClick={() => {
              navigate("/product-list/gold-akshaya-tritiya-voucher-jewellery");
            }}
          /> */}
          <LazyLoadImage
            src={gem_necklace1}
            alt="mobile-banner"
            height="395px"
            width="100px"
          />
          <LazyLoadImage
            src={gem_necklace2}
            alt="mobile-banner"
            height="395px"
            width="100px"
          />
          <LazyLoadImage
            src={gem_necklace3}
            alt="mobile-banner"
            height="395px"
            width="100px"
          />
        </Slider>
      </div>
    );
  };

  const DiamondBelowTwoLakhs = () => {
    return (
      <>
        <LazyLoadImage
          onClick={() => imgChange3("diamond necklace")}
          class="image_css"
          src={diamond_below_two}
          alt="diamond necklace below two lahks"
          width="1350px"
          height="395px"
        />
      </>
    );
  };

  const goldBelowTwoLakhs = () => {
    return (
      <>
        <LazyLoadImage
          onClick={() => imgChange2("gold necklace")}
          class="image_css"
          src={gold_below_two}
          alt="gold necklace below two lahks"
          width="1350px"
          height="395px"
        />
      </>
    );
  };

  const FeatureContainer = () => {
    return (
      <div class="feature_container flt">
        <div class="row">
          <div class="col-sm-3">
            <div class="feature_box flt">
              <div class="feature_icon">
                <LazyLoadImage
                  src={feature1}
                  alt="image"
                  height="50px"
                  width="50px"
                />
              </div>
              <div class="feature_txt">
                <span>SECURED INSURED SHIPPING</span>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="feature_box flt">
              <div class="feature_icon">
                <LazyLoadImage
                  src={feature2}
                  alt="image"
                  height="50px"
                  width="50px"
                />
              </div>
              <div class="feature_txt">
                <span>VALUE FOR MONEY</span>
                <br />
                Pay for the weight of gold not stones
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="feature_box flt">
              <div class="feature_icon">
                <LazyLoadImage
                  src={feature3}
                  alt="image"
                  height="50px"
                  width="50px"
                />
              </div>
              <div class="feature_txt">
                <span>CALL SUPPORT</span>
                <br />
                Call 1800 5999 777
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="feature_box flt">
              <div class="feature_icon">
                <LazyLoadImage
                  src={feature4}
                  alt="image"
                  height="50px"
                  width="50px"
                />
              </div>
              <div class="feature_txt">
                <span>CERTIFIED JEWELLERY</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const renderHeader = () => {
    return <Header count={wishListCount} cart={cartCount} />;
  };
  const renderFooter = () => {
    return <Footer />;
  };
  const renderCategoryContainer = () => {
    return <CategoryContainer onTopMainCategories={onTopMainCategories} />;
  };

  const renderWhatsNew = () => {
    return <WhatsNew onShopNowClicked={onShopNowClicked} />;
  };

  const closeVajrabharana = () => {
    sessionStorage.setItem("sessionVisited", "true");
    setShowModal(false);
  };

  const VajrabharanaWhatsapp = () => {
    sessionStorage.setItem("sessionVisited", "true");
    setShowModal(false);
  };

  return (
    <div>
      {renderHeader()}
      {BannerContainer()}
      {renderCategoryContainer()}
      {renderWhatsNew()}
      {/* {DiamondBelowTwoLakhs()} */}
      {/* {goldBelowTwoLakhs()} */}
      {showModal && Vajrabharana()}
      {FeatureContainer()}
      {renderFooter()}
    </div>
  );
};
export default HomePage;
