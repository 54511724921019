import "./RazorPay.css";
import { useSelector, useDispatch } from "react-redux";
import { ProductService } from "../../../services";
import { alertActions } from "../../../actions";

export const useRazorpay = () => {
  const email = useSelector((state) => state?.userData?.user?.email);
  const dispatch = useDispatch();
  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const displayRazorpay = async (checkOutData, product_id) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      return alert("sdk failed to load,Are u offline??!!");
    }
    let text = checkOutData?.data?.callback_url;
    const myArray = text.split("/");
    // const resOrderId = checkOutData.data.callback_url.substr(
    //   checkOutData.data.callback_url.length - 7
    // );
    const resOrderId = myArray[6];

    const order_id = checkOutData.data.order_id;
    const amount = checkOutData.data.amount;

    const currency = "INR";

    const options = {
      key: checkOutData.data.key,

      amount: amount.toString(),
      currency: currency,

      description: "Abharan Transaction",
      order_id: order_id,
      logo: "https://abharan.com/assets/images/logo.png",
      handler: async function (response) {
        if (
          !(
            typeof response.razorpay_payment_id === "undefined" ||
            response.razorpay_payment_id < 1
          )
        ) {
          ProductService.payment_success(resOrderId)
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                window.location.href = `http://development.abharan.com/thank-you?product_id=${product_id}&order_id=${resOrderId}`;
              }
            })
            .catch((error) => {
              console.log("Errorreg", error);
            });
        } else {
          ProductService.payment_failure(resOrderId)
            .then((response) => {
              if (response?.data?.status?.code === 0) {
                dispatch(alertActions.error(response?.data?.status?.message));
              }
            })
            .catch((error) => {
              console.log("Errorreg", error);
            });
        }
      },
      prefill: {
        name: checkOutData.data.name,
        email: email,
      },
      readonly: { email: true },

      theme: {
        color: "black",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };
  return {
    loadScript,
    displayRazorpay,
  };
};
