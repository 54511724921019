/* eslint-disable no-lone-blocks */
import prof1 from "../../../assets/images/prof1-brown.png";
import prof2 from "../../../assets/images/prof2-white.png";
import prof3 from "../../../assets/images/prof3-brown.png";
import prof4 from "../../../assets/images/prof4-brown.png";
import invoice from "../../../assets/images/download-invoice.png";
import problem from "../../../assets/images/report-problem.png";
import return_order from "../../../assets/images/return_order.png";
import { useState, useEffect } from "react";
import { OrderService } from "../../../services/OrderService";
import { ProductService } from "../../../services/ProductService";
import sample from "../../../assets/images/default.png";
import close_pop from "../../../assets/images/close-pop.png";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions/alert.actions";
import { useSelector } from "react-redux";
import empty_order from "../../../assets/images/empty-order.png";
import { useNavigate } from "react-router-dom";

const useOrderHooks = () => {
  const dispatch = useDispatch();
  const [order, setOrder] = useState();
  const [orderP, setOrderP] = useState();
  const [show, setShow] = useState(false);
  const [userId, setUserId] = useState();
  const [showEnquiry, setShowEnquiry] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [sku, setSku] = useState();
  const [product, setProduct] = useState();
  const [order_id, setOrder_id] = useState();
  const [message, setMessage] = useState();
  const [display, setDisplay] = useState(1);
  const navigate = useNavigate();
  const [prodValue, setProdValue] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [image, setImage] = useState();
  const [fileName, setFileName] = useState("");
  const [showButton, setShowButton] = useState(false);
  const [pan_no, setPancard] = useState("");
  const [orderId, setOrderId] = useState();
  const [resUpload, setResUpload] = useState({});
  const usr_id = useSelector((state) => state?.userData?.user?._id);
  const [doccur_num, setDoccur_num] = useState("");
  const [showTrack, setShowTrack] = useState(false);
  const [trackDetails, setTrackDetails] = useState([]);
  const [orderid, setOrderid] = useState();
  const [statusData, setStatusData] = useState("");
  const user_id = useSelector((state) => state?.userData?.user?._id);
  const [newDataStatus, setNewDataStatus] = useState("");
  const [estimatedate, setEstimateDate] = useState("");
  const [logisticCompany, setLogisticCompany] = useState("");
  const [estimatedate2, setEstimateDate2] = useState("");
  const [showCancel, setShowCancel] = useState(false);
  const [orderNo, setOrderNo] = useState();
  const [showReturnOrder, setShowReturnOrder] = useState(false);
  const [trackStatus, setTrackStatus] = useState();
  const [cancelledOrders, setCancelledOrders] = useState();
  const [returnedOrders, setReturnedOrders] = useState();

  useEffect(() => {
    ProductService.ongoinOrder()
      .then((order) => {
        setOrderP(order?.data?.data?.orders);

        // setOrder(order.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });
  }, []);

  useEffect(() => {
    if (resUpload?.data?.status?.code == 0) {
      setSubmitted(false);
      setShowUpload(false);
      dispatch(alertActions.success(resUpload?.data?.status?.message));
    } else {
      dispatch(alertActions.error(resUpload?.data?.status?.message));
    }
  }, [resUpload]);
  useEffect(() => {
    if (!selectedFile) {
      setImage(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setImage(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  function changeHandler(e) {
    e.preventDefault();
    setSubmitted(true);
    if (e.target.name == "message") {
      setMessage(e.target.value);
    }
  }
  function onSelectFile(e) {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setFileName(e.target.files[0].name);
    setSelectedFile(e.target.files[0]);
    setShowButton(true);
  }
  function changePancardHandler(e) {
    setPancard(e.target.value);
  }
  const openEnquiry = (order_list) => {
    setShowEnquiry(true);
    setName(order_list?.user?.first_name);
    setMobile(order_list?.user?.mobile_no);
    setSku(order_list?.product?.SKU);
    setProduct(order_list?.product?.name);
    setOrder_id(order_list?.order_no);
  };
  const openUpload = (id) => {
    setOrderId(id);
    setShowUpload(true);
  };
  const downloadInvoice = (id) => {
    if (user_id) {
      var link = document.createElement("a");

      link.setAttribute("download", "Invoice.pdf");
      link.href =
        `https://dev-services.abharan.com/api/v1/abharana/printOrder/` + id;
      // link.href = "data:application/pdf;base64," + baseData;

      document.body.appendChild(link);
      link.setAttribute("target", "_blank");
      //link.download = "file.pdf";
      link.click();
      link.remove();
    } else {
      navigate("/login");
    }
  };
  const downloadInvoiceCopy = (invoiceUrl) => {
    if (invoiceUrl) {
      // You can use the 'download' attribute to specify the filename when downloaded
      var link = document.createElement("a");
      link.setAttribute("download", "Invoice.pdf");
      link.href =
        `https://d2hazhkwxu3du8.cloudfront.net/development` + invoiceUrl;

      document.body.appendChild(link);
      link.setAttribute("target", "_blank");
      link.click();
      link.remove();
      //document.body.removeChild(link);
    } else {
      console.error("Invoice URL not available");
    }
  };
  const closeEnquiry = () => {
    setShowEnquiry(false);
  };
  const closeUpload = () => {
    setShowUpload(false);
  };

  const showPop = (id) => {
    setUserId(id);
    setShow(!show);
  };
  function handleClose(e) {
    setSelectedFile("");
    setFileName("");
    setImage("");
    onSelectFile(e);
  }
  //re-upload
  function handleUpload(e) {
    e.preventDefault();
    setSubmitted(true);
    var pattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (orderId && pan_no && image) {
      if (pan_no && !pattern.test(pan_no)) {
        dispatch(alertActions.error("Enter valid pancard number"));
      } else if (!image) {
        dispatch(alertActions.error("Upload the image of pancard"));
      } else {
        OrderService.reUpload(orderId, pan_no, selectedFile)
          .then((response) => {
            setResUpload(response);
            // setLoading(false);
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  }
  //display product
  const displayProduct = (display) => {
    const prodNameData = display?.product?.name
      .toLowerCase()
      .replace(/\s+/g, "-");
    const baseProductName = display?.product?.name.split(" ")[0].toLowerCase();
    navigate(
      `/product-details/${baseProductName}/${prodNameData}/${display.product.SKU.toLowerCase()}`,
      {
        state: { id: display.product.SKU, userid: usr_id },
      }
    );
    setProdValue(display);
  };

  const ongoingOrder = () => {
    setDisplay(2);
    OrderService.getData()
      .then((order) => {
        setOrder(order.data.data);
        setTrackStatus(order.data.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        //   setLoading(false);ongoinOrder
        return false;
      });
  };
  const ongoingOrder1 = () => {
    setDisplay(1);
  };
  const ongoingOrder2 = () => {
    setDisplay(3);
    // To get cancel order data
    OrderService.getCancelRequest()
      .then((order) => {
        setCancelledOrders(order?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });
  };
  const ongoingOrder3 = () => {
    setDisplay(4);
    OrderService.getReturnedRequest()
      .then((order) => {
        setReturnedOrders(order?.data?.data);
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });
  };
  const submitProblem = () => {
    OrderService.sendProblems(name, mobile, sku, product, message, order_id)
      .then((value) => {
        dispatch(alertActions.success(value.data.status.message));
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
    closeEnquiry();
    setMessage("");
  };

  //track my order
  const trackMyOrder = (data) => {
    {
      setShowTrack(true);
      if (data?.estimated_delivery_date_confirm) {
        setEstimateDate(data?.estimated_delivery_date_confirm);
      } else {
        data?.is_outof_stock === 1
          ? ProductService.estimated_delivery_outstock(
              data?.shipping_pincode,
              data?.product_id
            ).then((response) => {
              let text = response?.data;
              setEstimateDate(
                response?.data?.data?.estimated_delivery
                  ? response?.data?.data?.estimated_delivery
                  : text?.split(":")?.[5]?.split(/[" "$]/)?.[1]
              );
              setLogisticCompany(response?.data?.data?.message);
            })
          : ProductService.checkAvailability(data?.shipping_pincode).then(
              (response) => {
                let text = response?.data;
                setEstimateDate(
                  response?.data?.data?.estimated_delivery
                    ? response?.data?.data?.estimated_delivery
                    : text?.split(":")?.[4]?.split(/[" "$]/)?.[1]
                );
                setLogisticCompany(response?.data?.data?.message);
              }
            );
      }
    }

    if (data.track_status === 0 && data.service !== "Abharan") {
      setShowTrack(true);
      setNewDataStatus("");
      setStatusData("");
      setDoccur_num("");
    } else if (data.track_status !== 0 && data.service !== "Abharan") {
      setShowTrack(true);
      setStatusData(data?.track_status);
      ProductService.doctrack(data?.order_no).then((response) => {
        setNewDataStatus(response?.data);
        setDoccur_num(
          response?.data?.DocketNo
            ? response?.data?.DocketNo
            : response?.data?.data?.docket_no
        );
        document.body.classList.add("modal-open");
      });
    } else {
      ProductService.doctrack(data?.order_no).then((response) => {
        setNewDataStatus(response?.data);
        // console.log(response?.data?.data?.tracking?.[0]?.code)
        {
          response?.data?.DocketTrackList
            ? setStatusData(response?.data?.DocketTrackList?.[0]?.TRACKING_CODE)
            : setStatusData(
                response?.data?.data?.tracking?.[
                  response?.data?.data?.tracking.length - 1
                ]?.code
                  ? response?.data?.data?.tracking?.[
                      response?.data?.data?.tracking.length - 1
                    ]?.code
                  : response?.data?.data?.track_status
              );
        }
        // setStatusData("O")
        setShowTrack(true);
        // setOrderid(response?.data?.OrderNo ? response?.data?.OrderNo : response?.data?.data?.order_no);
        setDoccur_num(
          response?.data?.DocketNo
            ? response?.data?.DocketNo
            : response?.data?.data?.docket_no
        );
        document.body.classList.add("modal-open");
      });
    }
    setOrderid(data?.order_no ? data?.order_no : "");
  };

  const closeTrackOrder = () => {
    setShowTrack(false);
    document.body.classList.remove("modal-open");
  };

  //console.log(newDataStatus.data?.service, "hdsajkfhdkjh");

  const cancelTrackOrder = (data) => {
    // console.log(data,"jhdsfgfhdsh")
    setOrderNo(data.order_no);

    setShowCancel(true);
  };
  const cancelTracks = () => {
    setShowCancel(false);
  };

  //retrurn order pop-up
  const trackReturnOrder = (data) => {
    setOrderNo(data.order_no);
    setShowReturnOrder(true);
  };

  const cancelOrderTrack = () => {
    setShowReturnOrder(false);
  };

  // Cancelling the order
  const cancelOrder = () => {
    ProductService.cancelOrder(orderNo)
      .then((response) => {
        if (response.data.status.code === 0) {
          ProductService.ongoinOrder()
            .then((order) => {
              setOrderP(order?.data?.data?.orders);
            })
            .catch((error) => {
              console.log("Errorreg", error);
              return false;
            });
          dispatch(alertActions.success(response.data.status.message));
        } else if (response.data.status.code === 1) {
          dispatch(alertActions.error(response.data.status.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });

    setShowCancel(false);
  };
  //retutrn order
  const returnOrder = () => {
    ProductService.returnOrder(orderNo)
      .then((response) => {
        if (response.data.status.code === 0) {
          OrderService.getData()
            .then((order) => {
              setOrder(order.data.data);
              setTrackStatus(order.data.data);
            })
            .catch((error) => {
              console.log("Errorreg", error);
              //   setLoading(false);ongoinOrder
              return false;
            });
          dispatch(alertActions.success(response.data.status.message));
        } else if (response.data.status.code === 1) {
          dispatch(alertActions.error(response.data.status.message));
        }
      })
      .catch((error) => {
        console.log("Errorreg", error);
        return false;
      });

    setShowReturnOrder(false);
  };

  return {
    prof1,
    prof2,
    prof3,
    prof4,
    invoice,
    problem,
    order,
    sample,
    showPop,
    show,
    userId,
    close_pop,
    return_order,
    closeEnquiry,
    closeUpload,
    showEnquiry,
    showUpload,
    openEnquiry,
    openUpload,
    name,
    mobile,
    sku,
    product,
    order_id,
    message,
    changeHandler,
    submitProblem,
    submitted,
    downloadInvoice,
    downloadInvoiceCopy,
    cancelOrder,
    empty_order,
    display,
    ongoingOrder,
    ongoingOrder1,
    orderP,
    displayProduct,
    onSelectFile,
    image,
    selectedFile,
    fileName,
    handleClose,
    showButton,
    changePancardHandler,
    pan_no,
    handleUpload,
    trackMyOrder,
    doccur_num,
    showTrack,
    closeTrackOrder,
    trackDetails,
    orderid,
    statusData,
    newDataStatus,
    estimatedate,
    estimatedate2,
    showCancel,
    showReturnOrder,
    returnOrder,
    cancelTrackOrder,
    cancelTracks,
    trackReturnOrder,
    cancelOrderTrack,
    cancelledOrders,
    returnedOrders,
    logisticCompany,
    cancelTrackOrder,
    cancelTracks,
    ongoingOrder2,
    ongoingOrder3,
  };
};
export default useOrderHooks;
